import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Bilder() {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { sourceInstanceName: { eq: "studio" } }
        sort: { fields: name }
      ) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  const galleryImages = data.allFile.nodes
  return (
    <div>
      {" "}
      <div className="gallery grid gap-4 md:gap-8 w-full">
        {galleryImages.map((image, i) => {
          return <GalleryImage key={i} image={image} />
        })}
      </div>
    </div>
  )
}

function GalleryImage({ image }) {
  const img = image.childImageSharp
  return (
    <div className="">
      <GatsbyImage
        image={getImage(img)}
        alt="Featured"
        className="object-cover w-full h-full"
      />
    </div>
  )
}
