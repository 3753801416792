import React from "react"
import Link from "./common/Link"
export default function Presentation() {
  return (
    <div className="space-y-4">
      <h2>Welcome to Studio 73A</h2>
      <p>
        Professional studio rental for film and photography production, located
        in central Malmö
      </p>

      <p>
        Studio area 129m2, with 5,5m ceiling height. Additional 40 m2 for props
        storage. Daylight windows facing east and west.
      </p>

      <p>
        Access to kitchen, 16 amp power supply, wifi, (colorama) background
        support, apple boxes, poly boars with stands, large reflectors black and
        white, computer table, clothing racks and coffee.
      </p>

      <p>
        Loading dock in connection to the studio. Freight elevator (L 1,46m, W
        1,46m, H 1,65m) down to the studio that loads euro pallets.
      </p>

      <p>Easy access and free parking both for cars and trucks.</p>

      <p>
        <b>Price</b>
        <br />
        3500 kr per day (8 hours)
        <br />
        Overtime: 500 kr per hour
        <br />
      </p>

      <p>
        Please{" "}
        <Link to="/contact" className="underline">
          contact
        </Link>{" "}
        us if you have any questions and we will help you out.
      </p>
    </div>
  )
}
