import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bilder from "../components/common/Bilder"
import Presentation from "../components/Presentation"

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Studio 73A | Professional photo and film studio rental located in central Malmö"
        isHome
      />
      <div className="flex flex-col gap-8 w-full">
        <Presentation />
        <Bilder />
      </div>
    </Layout>
  )
}
